import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import {
  getRequests,
  getDispatcherRequests,
  getCloneRequests,
} from 'app/main/Requests/store/requestsSlice';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseNavItem from '../FuseNavItem';
import store from '../../../../app/main/ID_store/store';
import AuthReducer from 'app/stores/loadz/redux/Auth/index';
import { connect } from 'react-redux';
import { injectReducer } from 'app/store';
import { useHistory } from 'react-router-dom';
import client from 'client';
import { motion } from 'framer-motion';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import { PERMISSION_MODEL } from '../../../../constants';
import { hasPermission } from 'app/main/loadz/utils';
import JobReducer from 'app/stores/loadz/redux/Jobs';
import JobAction from 'app/stores/loadz/redux/Jobs/actions';
const moment = require('moment');

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  '& .fuse-list-item': {
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
    },
    '&:focus:not(.active)': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
    },
  },
  '&.active-square-list': {
    '& .fuse-list-item, & .active.fuse-list-item': {
      width: '100%',
      borderRadius: '0',
    },
  },
  '&.dense': {
    '& .fuse-list-item': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
  },
}));

function FuseNavVerticalLayout1({
  navigation,
  layout,
  active,
  dense,
  className,
  onItemClick,
  company,
  user,
  getPendingJobs,
  pending_jobs_count,
}) {
  //this is from the request slice, in an attempt to re load the requests when a request is completed
  const request_load = useSelector(({ requests }) => requests?.request?.request_load);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ActiveStep, setActiveStep] = useState(null);
  const [counterDriver, setCounterDriver] = useState(null);
  const [counterSub, setCounterSub] = useState(null);
  const [counterOwner, setCounterOwner] = useState(null);
  const [counterUser, setCounterUser] = useState(null);
  const [newNavigation, setNewNavigation] = useState([]);
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [hidden, setHidden] = useState(true);
  const [open, setOpen] = useState(false);
  let { comp_acct } = store.getState();

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  function handleItemClick(item) {
    onItemClick?.(item);
  }

  const callApiChangeNavigation = async () => {
    if (navigation && navigation.length > 0) {
      if (!comp_acct) {
        comp_acct = company?.compacctnum;
      }
      const getApiRes = await dispatch(getRequests(comp_acct));
      const getApiDisRes = await dispatch(getDispatcherRequests(comp_acct));
      const getApiCloneRes = await dispatch(getCloneRequests(comp_acct));
      await getPendingJobs({ compacctnum: comp_acct }, (error) => {
        console.log('Error getting pending jobs at nav bar: ', error);
      });

      let count = 0;
      let dis_count = 0;
      let clone_count = 0;
      if (
        getApiRes &&
        getApiRes.payload &&
        getApiRes.payload.requests &&
        getApiRes.payload.requests.length > 0
      ) {
        count = getApiRes.payload.requests.filter((obj) => !obj.resolved).length;
      }
      if (
        getApiDisRes &&
        getApiDisRes.payload &&
        getApiDisRes.payload.requests &&
        getApiDisRes.payload.requests.length > 0
      ) {
        dis_count = getApiDisRes.payload.requests.filter((obj) => !obj.resolved).length;
      }
      if (
        getApiCloneRes &&
        getApiCloneRes.payload &&
        getApiCloneRes.payload.requests &&
        getApiCloneRes.payload.requests.length > 0
      ) {
        clone_count = getApiCloneRes.payload.requests.filter((obj) => !obj.resolved).length;
      }
      count = count + dis_count + clone_count;
      let newArray = [];
      for (const _item of navigation) {
        let obj = { ..._item };
        if (obj?.children && obj?.children.length > 0) {
          obj?.children.forEach((childItem) => {
            if (childItem?.id == 'dashboard') {
              if (count > 0) {
                childItem.countofPending = { title: count, bg: 'red', fg: 'white' };
              } else {
                childItem.countofPending = null;
              }
            }
            if (childItem?.id == 'jobs') {
              if (pending_jobs_count > 0) {
                childItem.countofPending = { title: pending_jobs_count, bg: 'red', fg: 'white' };
              } else {
                childItem.countofPending = null;
              }
            }

            if (childItem?.children && childItem?.children.length > 0) {
              const children = childItem?.children.map((c) => {
                const countofPending =
                  c.id == 'requests'
                    ? { title: count, bg: 'red', fg: 'white' }
                    : c.id == 'jobs_drafts'
                    ? { title: pending_jobs_count, bg: 'red', fg: 'white' }
                    : null;
                return { ...c, countofPending };
              });
              childItem.children = children;
            }
          });
        }
        newArray.push(obj);
      }
      setNewNavigation(newArray);
    }
  };

  //the function runs again due to the request_load variable but the navigation bar does not re load
  useEffect(async () => {
    callApiChangeNavigation();
  }, [navigation, request_load, pending_jobs_count]);

  const dwollaClick = () => {
    if (user?.user_id) {
      history.replace(`/dwolla/intro`);
    }
  };

  const counterUsers = async () => {
    if (!company?.compacctnum) {
      return;
    }
    const result = await client.post(`/subscription/subscription-counter/${company?.compacctnum}`);
    setCounterDriver(result.data.driverCount);
    setCounterOwner(result.data.ownerCountDrivers.sum);
    setCounterSub(result.data.subCount);
    setCounterUser(result.data.usersCount);
  };

  const subscriptionCompany = async () => {
    try {
      const response = await client.get(
        `/subscription/subscription-company/${company?.compacctnum}`
      );

      if (response.data[0].status != 'ontrial') {
        setHidden(false);
      }

      const start = moment(response.data[0].trial_start);
      const trialDuration = response.data[0].days_of_trial_period_comp
        ? response.data[0].days_of_trial_period_comp
        : response.data[0].days_of_trail_period;
      const now = moment();
      const endOfTrial = start.clone().add(trialDuration, 'days');
      const daysRemaining = endOfTrial.diff(now, 'days');
      setDaysRemaining(daysRemaining);
    } catch (error) {
      console.log(error);
      setHidden(false);
    }
  };

  useEffect(() => {
    async function handleSave() {
      const result = await client.get(`/company/${user?.user_id}`);
      setActiveStep(result.data.types_steps_checkouts_id);
    }
    handleSave();
    counterUsers();
    subscriptionCompany();
  }, [user?.user_id]);

  const managePaymentMethods = async () => {
    history.push('/subscriptions-company');
  };

  return (
    <div>
      <StyledList
        className={clsx(
          'navigation whitespace-nowrap px-12',
          `active-${active}-list`,
          dense && 'dense',
          className
        )}
      >
        {newNavigation.map((_item) => (
          <>
            {_item.tooltip ? (
              <div key={_item.id} style={{ position: 'relative' }}>
                <FuseNavItem
                  key={_item.id}
                  type={`vertical-${_item.type}`}
                  item={_item}
                  nestedLevel={0}
                  onItemClick={handleItemClick}
                  request_load={request_load}
                />
                <HtmlTooltip
                  title={_item.tooltip}
                  style={{ position: 'absolute', right: 0, top: 0 }}
                >
                  <IconButton>
                    <InfoOutlinedIcon sx={{ color: 'black' }} />
                  </IconButton>
                </HtmlTooltip>

                {_item.id == 'subcontractors-new' && counterSub > 0 && (
                  <IconButton style={{ position: 'absolute', right: 25, top: 3 }}>
                    <div className='item-badge px-3 bg-black text-white rounded-full text-xs'>
                      {counterSub}
                    </div>
                  </IconButton>
                )}

                {_item.id == 'owner-operators' && counterOwner > 0 && (
                  <IconButton style={{ position: 'absolute', right: 25, top: 3 }}>
                    <div className='item-badge px-3 bg-black text-white rounded-full text-xs'>
                      {counterOwner}
                    </div>
                  </IconButton>
                )}

                {_item.id == 'dispatch' && (
                  <h7
                    className='item-badge bg-black text-xs  rounded-full px-2 py-1'
                    style={{
                      position: 'absolute',
                      right: 118,
                      top: 18,
                      color: '#edca33',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    Beta
                  </h7>
                )}

                {_item.id == 'agent' && (
                  <h7
                    className='item-badge bg-black text-xs  rounded-full px-2 py-1'
                    style={{
                      position: 'absolute',
                      right: 118,
                      top: 18,
                      color: '#edca33',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    Beta
                  </h7>
                )}
              </div>
            ) : (
              <>
                <div style={{ position: 'relative' }}>
                  <FuseNavItem
                    key={_item.id}
                    type={`vertical-${_item.type}`}
                    item={_item}
                    nestedLevel={0}
                    onItemClick={handleItemClick}
                    request_load={request_load}
                  />
                  {_item.id == 'driverlist' && counterDriver > 0 && (
                    <IconButton style={{ position: 'absolute', right: 25, top: 3 }}>
                      <div className='item-badge px-3 bg-black text-white rounded-full text-xs'>
                        {counterDriver}
                      </div>
                    </IconButton>
                  )}

                  {_item.id == 'stafflist' && counterUser > 0 && (
                    <IconButton style={{ position: 'absolute', right: 25, top: 3 }}>
                      <div className='item-badge px-3 bg-black text-white rounded-full text-xs'>
                        {counterUser}
                      </div>
                    </IconButton>
                  )}

                  {_item.id == 'dispatch' && (
                    <h7
                      className='item-badge bg-black text-xs  rounded-full px-2 py-1'
                      style={{
                        position: 'absolute',
                        right: 134,
                        top: 18,
                        color: '#edca33',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                      }}
                    >
                      Beta
                    </h7>
                  )}

                  {_item.id == 'agent' && (
                    <h7
                      className='item-badge bg-black text-xs  rounded-full px-2 py-1'
                      style={{
                        position: 'absolute',
                        right: 118,
                        top: 18,
                        color: '#edca33',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                      }}
                    >
                      Beta
                    </h7>
                  )}
                </div>
              </>
            )}
          </>
        ))}
      </StyledList>

      {user?.user_type != 'super-admin' && hasPermission(PERMISSION_MODEL.fastPayAccess) && (
        <div className='flex flex-col items-center'>
          {hidden ? (
            <Grid className='flex flex-grow  justify-center'>
              <Paper
                style={{
                  marginTop: '20px',
                  padding: '16px',
                  textAlign: 'center',
                  minHeight: '100px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  borderRadius: '0',
                  backgroundColor: 'transparent', // Fondo transparente
                  border: '1px solid #000', // Borde negro de 1px, ajusta el color como prefieras
                }}
              >
                <Typography variant='h6'>FREE TRIAL ENDS IN</Typography>
                <Typography
                  variant='h2'
                  style={{
                    color: '#FFD700',
                    fontWeight: 'bold',
                    fontSize: '4rem',
                  }}
                >
                  {daysRemaining}
                </Typography>
                <Typography variant='body2'>DAYS</Typography>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: '#000',
                    color: '#FFF',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                  }}
                  onClick={() => {
                    managePaymentMethods();
                  }}
                >
                  Update Billing Details
                </Button>
              </Paper>
            </Grid>
          ) : null}

          {ActiveStep != 8 ? (
            <>
              <div className='flex pt-40 pb-40'>
                <motion.div variants={item} className='flex flex-row items-center justify-center'>
                  <img
                    onClick={dwollaClick}
                    className='w-4/6 items-center cursor-pointer'
                    src='assets/images/fastpaybanner 2.png'
                    alt='logo'
                  />
                </motion.div>
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}

injectReducer('loadz_auth', AuthReducer);
injectReducer('loadz_jobs', JobReducer);

const mapDispatchToProps = (dispatch) => ({
  getPendingJobs: (payload, onError) => dispatch(JobAction.getPendingJobs(payload, onError)),
});

const mapStateToProps = (state) => {
  return {
    company: state?.loadz_auth?.AuthReducer?.user?.company,
    user: state?.loadz_auth?.AuthReducer?.user,
    pending_jobs_count: state.loadz_jobs?.JobReducer?.pending_count,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FuseNavVerticalLayout1);
