import ECommerceAppConfig from './e-commerce/ECommerceAppConfig';
import CatMaterialsAppConfig from './catMaterials/CatMaterialsAppConfig';
import UnitTempsAppConfig from './unitTempsMaterials/UnitTempsAppConfig';
import TruckAppConfig from './Truck/TruckAppConfig';
import InspectionAppConfig from './Inspection/InspectionAppConfig';
import TruckdetailsAppConfig from './Truckdetails/TruckdetailsAppConfig';
import TrailerAppConfig from './T_p_Typehaul/T_p_TypehaulAppConfig';
import TicketsStatusReference from './ticketsStatusReference/TkStatusAppConfig';
import DashboardConfig from './Dashboard/DashboardConfig';
import JobsConfig from './Jobs/JobsConfig';
import TypeHaulAppConfig from './typeHaul_type/TypeHaulAppConfig';
import assinglogAppConfig from './assinglog/AssinglogAppConfig';
import LocationsAppConfig from './locations/LocationsAppConfig';
import CustomersConfig from './Customers/CustomersConfig';
import chatAppConfig from './chat/NewInspectionAppConfig';
import ProfilePageConfig from './profile/ProfilePageConfig';
import AccountPageConfig from './account/AccountPageConfig';
import TicketPageConfig from './Ticket_jobs/TicketPageConfig';
import MileageAppConfig from './mileage/MileageAppConfig';
import DpsAppConfig from './Dps/DpsAppConfig';
import BatchesConfig from './Invoices/Uninvoiced/UninvoicedConfig';
import StaffConfig from './Staff/StaffConfig';
import SubconConfig from './Subcontractor/SubconConfig';
import DriverChatAppConfig from './DriverChat/DriverChatAppConfig';
import DownloadAppConfig from './Register_v2/DownloadAppConfig';
import UnitCostConfig from './Register_v2/SubsUnitCost/UnitCostConfig';
import RequestAppConfig from './Requests/RequestAppConfig';
import TruckModalConfig from './Truck/tabs/TruckModalConfig';
import ManageVideosConfig from './catMaterials/MaterialCategories/ManageVideosConfig';
import OwneropAppConfig from './OwnerOps/OwneropAppConfig';
import DwollaWelcomeConfig from './Dwolla/DwollaWelcomeConfig';
import SimpleActivationConfig from './Register_v2/EmployeeRegisterForms/SimpleActivationConfig';
import SubscriptionsManagementConfig from './subscriptionManagement/SubscriptionsManagementConfig';
import SubClientActivationConfig from './Register_v2/Utils/ApplyPaymentTabs/CustomerPortal/SubClientActivationConfig';
import DispatchAppConfig from './Dispatch/DispatchAppConfig';
import GraduationConfig from './Graduation/GraduationConfig';
import JobsDraftsConfig from './JobDrafts/JobsConfig';
import ConfirmDispatchAppConfig from './Dispatch/ConfirmDispatchAppConfig';
import ConfirmAssetDispatchAppConfig from './Dispatch/ConfirmAssetDispatchAppConfig';
import OpConfirmDispatchAppConfig from './Dispatch/OpConfirmDispatchAppConfig';
import AgentAppConfig from './agents/AgentAppConfig';
import EmailConfigurationConfig from './loadz/view/email-configuration/EmailConfigurationConfig';

const appsConfigs = [
  TruckModalConfig,
  ECommerceAppConfig,
  CatMaterialsAppConfig,
  UnitTempsAppConfig,
  InspectionAppConfig,
  TruckAppConfig,
  TruckdetailsAppConfig,
  TicketsStatusReference,
  DashboardConfig,
  JobsConfig,
  TypeHaulAppConfig,
  TrailerAppConfig,
  assinglogAppConfig,
  LocationsAppConfig,
  CustomersConfig,
  chatAppConfig,
  MileageAppConfig,
  DpsAppConfig,
  //ProfilePageConfig,
  AccountPageConfig,
  TicketPageConfig,
  BatchesConfig,
  StaffConfig,
  SubconConfig,
  DriverChatAppConfig,
  DownloadAppConfig,
  UnitCostConfig,
  RequestAppConfig,
  ManageVideosConfig,
  OwneropAppConfig,
  DwollaWelcomeConfig,
  SimpleActivationConfig,
  SubscriptionsManagementConfig,
  SubClientActivationConfig,
  DispatchAppConfig,
  GraduationConfig,
  JobsDraftsConfig,
  ConfirmDispatchAppConfig,
  ConfirmAssetDispatchAppConfig,
  OpConfirmDispatchAppConfig,
  AgentAppConfig,
  EmailConfigurationConfig
];

export default appsConfigs;
