import React, { useRef, useState } from "react";
import Button from '@mui/material/Button';
import ReactToPrint from "react-to-print";
import InvoiceReport from './InvoiceReport'
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { hasPermission } from 'app/main/loadz/utils';

export default function InvoicePrintReport({company_id,groupByCustomer,groupByJob, compLogo,show, fullReportData, display, date_start, date_end,selectedCustomers,selectedJobs,selectedStatus}) {

    let start_date = date_start
    let end_date = date_end
    let enddate = date_end
    let has_date = true

     if(start_date && end_date)
    {
        if (moment(start_date).isAfter(end_date)) 
        {
            const temp = end_date;
            end_date = start_date;
            start_date = temp;
        }

        if (moment(end_date).isBefore(start_date)) 
        {
            const temp = end_date;
            end_date = start_date;
            start_date = temp;
        }
    }
    else if(start_date || enddate)
    {
        if(start_date && !enddate)
        {
            end_date = start_date
        }
        else if(!start_date && enddate)
        {
            start_date = end_date
        }
    }
    else
    {
      //no date get all
      has_date = false
    }

    if(has_date)
    {
      start_date = moment(start_date).format('YYYY-MM-DD');
      end_date = moment(end_date).format('YYYY-MM-DD');
    }

    let customer_list = []

    if(selectedCustomers.find((agent) => agent.customer_id == 'all'))
    {
      customer_list = ['all']
    }
    else
    {
      customer_list = selectedCustomers.map((i) => i.name)
    }

    //setup jobs to search for
    let job_list = []

    if(selectedJobs.find((job) => job.job_id == 'all'))
    {
      job_list = ['all']
    }
    else
    {
      job_list = selectedJobs.map((i) => i.job_no)
    }

    //setup status to search for
    let status_list = []

    if(selectedStatus.find((stat) => stat.id == 'all'))
    {
      status_list = ['all']
    }
    else
    {
      status_list = selectedStatus.map((i) => i.status)
    }



    let componentRef = useRef();


    const printTrigger = React.useCallback(() => {
        return  show ?
        (<IconButton style={{fontSize: 14}}>{show}&nbsp;<PrintIcon/></IconButton>) :
        (<IconButton><PrintIcon/></IconButton>)

    }, [])

    /* const handleOnBeforeGetContent = () => {
        earnings = {}
    } */

    var documentTitle = `${hasPermission('invoice.read') ? 'Invoice_Agent_Commission_Report' : 'Invoice_Report'}_STATUS_${status_list.join('_')}_CUSTOMERS_${customer_list.join('_')}_JOBS_${job_list.join('_')}_DATE_${has_date ? start_date + '_to_' + end_date: 'all'}`
 

    return (
        <>
        <div>
            {/* button to trigger printing of target component */}
            <ReactToPrint
            trigger={printTrigger}
            documentTitle={documentTitle}
            //onBeforeGetContent={handleOnBeforeGetContent}
            content={() => componentRef.current}
            />

            {/* component to be printed */}
            {
            display ? (
                <div className="w-full " style={{height:'200px'}}>
                <InvoiceReport company_id={company_id} usergroupByJob={groupByJob} usergroupByCustomer={groupByCustomer} compLogo={compLogo} fullReportData={fullReportData} has_date={has_date} start_date={start_date} end_date={end_date} ref={componentRef} />
                </div>
            ) : (
                <div style={{display: 'none'}}>
                <InvoiceReport company_id={company_id} usergroupByJob={groupByJob} usergroupByCustomer={groupByCustomer} compLogo={compLogo} fullReportData={fullReportData} has_date={has_date} start_date={start_date} end_date={end_date} ref={componentRef} />
                </div>
            )
            }
        </div>
        </>
    );
}
