import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { pick } from 'lodash';
import { Typography,Checkbox } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const validationSchema = Yup.object({
  agent_id: Yup.string().required('Agent is required'),
  commission_type: Yup.string().required('Commission type is required'),
  commission_amount: Yup.number().required('commission amount is required')
});


const AgentCommissionRateModal = ({ billing_exists,edited,open, handleClose, agentsOptions = [], onSave, selectedCommissionMatrix }) => {
  


  const initialValues = {
    agent_id: selectedCommissionMatrix ? selectedCommissionMatrix?.agent_id : null,
    commission_type: selectedCommissionMatrix ? selectedCommissionMatrix?.commission_type : '',
    commission_amount: selectedCommissionMatrix ? selectedCommissionMatrix?.commission_amount : '',
    retroactive_agent_commission: false,
    retroactive_agent_commission_edit:false
  }

  const onClose = () => {
    formik.setValues({
      agent_id: '',
      commission_type: '',
      commission_amount: '',
      retroactive_agent_commission: false,
      retroactive_agent_commission_edit:false
    })
    handleClose();
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      onSave(values)
      handleClose()
    }
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Agent Commissions Rate</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginBottom: '16px', paddingTop: '8px'}}>
            <Autocomplete
              disabled={selectedCommissionMatrix ? true : false}
              options={agentsOptions}
              getOptionDisabled={(option) => option.disabled}
              getOptionLabel={(option) => option.label}
              value={agentsOptions.length ? agentsOptions.find(i => i.value === formik?.values?.agent_id) : ''}
              onChange={(event, _value) => formik.setFieldValue('agent_id', _value.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agent"
                  variant="outlined"
                  error={formik.touched.agent_id && Boolean(formik.errors.agent_id)}
                  helperText={formik.touched.agent_id && formik.errors.agent_id}
                />
              )}
            />
          </div>
          <div className='flex flex-col md:flex-row mb-8'>
            <div className='mr-2 mt-8'>
              <Select
                name="commission_type"
                size='small'
                value={formik.values.commission_type}
                onChange={formik.handleChange}
                displayEmpty
                error={formik.touched.commission_type && Boolean(formik.errors.commission_type)}
                fullWidth
              >
                <MenuItem value="" disabled>Select Commission Type</MenuItem>
                <MenuItem value="fixed">Fixed Rate per Completed Ticket</MenuItem>
                <MenuItem value="percentage">Percentage of Unit Invoice Value per ticket</MenuItem>
                <MenuItem value="rate per quantiy">Flat Rate * Ticket Unit Quantity Hauled</MenuItem>
              </Select>
              {formik.touched.commission_type && formik.errors.commission_type && (
                <div style={{ color: 'red', marginTop: '4px' }}>
                  {formik.errors.commission_type}
                </div>
              )}
            </div>
            <div className='ml-2'>
              <TextField
                name="commission_amount"
                label="Commission Amount"
                type="number"
                size='small'
                value={formik.values.commission_amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                margin="dense"
                className="customTextField"
                error={formik.touched.commission_amount && Boolean(formik.errors.commission_amount)}
                helperText={formik.touched.commission_amount && formik.errors.commission_amount}
                InputProps={{ shrink: true }}
              />
            </div>
          </div>
          {
            billing_exists == false ? 
            null
            :
            edited ? 
            <div className='flex flex-row items-center justify-start align-center'>
              <Typography fontWeight={'bold'}>Retroactive</Typography>
              <Checkbox 
                name='retroactive_agent_commission_edit'
                value={formik.values.retroactive_agent_commission_edit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          :
            <div className='flex flex-row items-center justify-start align-center'>
              <Typography fontWeight={'bold'}>Retroactive</Typography>
              <Checkbox 
                name='retroactive_agent_commission'
                value={formik.values.retroactive_agent_commission}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          }
          
          {
            billing_exists == false ? 
            null
            :
            <div className='flex flex-row items-center justify-start align-center'>
              <ReportProblemOutlinedIcon sx={{color: 'red', fontSize:'15px'}} />
              {
                edited ? 
                <div className='flex flex-col justify-start '>
                  <Typography fontWeight={'bold'} color={'red'} >Recalculate all previous commissions,</Typography>
                  <Typography fontWeight={'bold'} color={'red'} >or only going forward</Typography>
                </div>
                :
                <div className='flex flex-col justify-start '>
                  <Typography fontWeight={'bold'} color={'red'} >Give agent commission for all tickets in the job,</Typography>
                  <Typography fontWeight={'bold'} color={'red'} >or only give commissions going forward</Typography>
                </div>
              }
              
            </div> 
          }
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant='contained' color='primary'>{edited ? 'Edit': 'Create'}</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AgentCommissionRateModal;
