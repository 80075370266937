import {
    Box,
    Card,
    Icon,
    LinearProgress,
    List,
    ListItem,
    Typography,
    Chip,
    Checkbox
  } from '@mui/material'
  import { useEffect, useRef, useState } from 'react'
  import { motion } from 'framer-motion'
  
  import client from 'client'
  import { showMessage } from 'app/store/fuse/messageSlice'
  import { useDispatch } from 'react-redux'
  import moment from 'moment'
  import FuseLoading from '@fuse/core/FuseLoading'
  import store from 'app/store'
  import { ReactMultiEmail } from 'react-multi-email';

  
  const EmailConfigEntry = ({emailGroup,email_name,index,onChange,onRemove,changeEnabled}) => {

    //The key determines the name of the group
    //emailGroup.enabled determines if the group is enabled , this will be a checkbox

    const nameGenerator = (key) => {
        let name = key.replaceAll('_',' ')
        let name_split = name.split(' ')
        let name_final = ''
        name_split.map((word) => {
            name_final += word.charAt(0).toUpperCase() + word.slice(1) + ' '
        })

        return name_final

    }

    return (
  
       <Card className='flex flex-col w-full p-16 gap-y-16' sx={{borderRadius:2}}>
        
        <Typography fontWeight={'bold'} variant='h6'>{email_name ? nameGenerator(email_name) : 'Group Name Missing'}</Typography>
        <ReactMultiEmail
            placeholder="Input your Email Address"
            emails={emailGroup.data}
            onChange={(_emails) => {
                console.log(_emails)
            onChange(index,_emails);
            }}
            getLabel={(email) => {
            return (
                <Chip
                className="mr-4"
                data-tag-handle
                label={email}
                onDelete={() => onRemove(index,email)}
                />
            );
            }}
        />
        <div className='flex flex-row justify-start align-center items-center'>
            <Typography>Enabled</Typography>
            <Checkbox
            checked={emailGroup.enabled}
            onChange={(e) => {changeEnabled(index,e.target.checked)}}
            />
        </div>
        

       </Card>
        
    )
  }
  
  
  export default EmailConfigEntry
  