import { getErrorAction, getSuccessAction, update } from '../utility';

const actions = {
  LOG_IN: 'login',
  REQUEST_CODE: 'request_to_code',
  RESET_STATE: 'resetState',
  GENERAL_REGISTRATION: 'generalRegistration',
  DO_ONBOARDING: 'doOnboarding',
  ONBOARDING_STEP: 'onboarding_step',
  SKIP_STEP: 'skip_step',
  LOG_OUT: 'logout',
  SET_COMPANY_LOGO: 'setCompanyLogo',
  SET_USER_LOGO: 'setUserLogo',
  UPDATE_VERSIONS: 'updateVersions',

  login: ({ credential, onSuccess, onError }) => {
    return {
      type: actions.LOG_IN,
      payload: credential,
      onSuccess,
      onError,
    };
  },

  requestCode: (payload, onSuccess, onError) => {
    return {
      type: actions.REQUEST_CODE,
      payload,
      onSuccess,
      onError,
    };
  },

  resetState: () => {
    return {
      type: actions.RESET_STATE,
    };
  },

  logout: () => {
    localStorage.removeItem('token');
    return {
      type: actions.RESET_STATE,
    };
  },

  generalRegistration: ({ credential, onSuccess, onError }) => {
    const { email, fname, lname, password, code, type, subscription_id, referred_by } = credential;
    return {
      type: actions.GENERAL_REGISTRATION,
      payload: { email, fname, lname, password, code, type, subscription_id, referred_by },
      onSuccess,
      onError,
    };
  },

  doOnboarding: ({ data, step, onSuccess, onError }) => {
    return {
      type: actions.DO_ONBOARDING,
      payload: data,
      step,
      onSuccess,
      onError,
    };
  },

  goBackOnboarding: () => {
    return {
      type: update(actions.ONBOARDING_STEP),
      payload: {},
    };
  },

  skipStep: () => {
    return {
      type: actions.SKIP_STEP,
      payload: {},
    };
  },

  setCompanyLogo: (payload, onError) => {
    return {
      type: actions.SET_COMPANY_LOGO,
      payload,
      onError,
    };
  },

  setUserLogo: (payload, onError) => {
    return {
      type: actions.SET_USER_LOGO,
      payload,
      onError,
    };
  },

  updateVersion: (payload) => {
    return {
      type: actions.UPDATE_VERSIONS,
      payload,
    };
  },
};

export default actions;
