import React, {useEffect, useState} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, {tableRowClasses} from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import AppBar from '@mui/material/AppBar';
import { color } from '@mui/system';
import store from 'app/store';
import Tooltip from '@mui/material/Tooltip';
import ErrorBoundary from '../ErrorBoundary';
import {formatter} from '../../../constants'
import './InvoiceReport.css'
import { TableFooter } from '@mui/material';
import { hasPermission } from 'app/main/loadz/utils';
const classes= {
    paper: {
       border: 'none',
       boxShadow: 'none',
       height: '80%',
       width: '95%',
       padding: 0,
       //backgroundColor: '#2549d9',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       background: 'none'
    },
    blank: {
       height: '0%',
       width: '0%',
       padding: 0,
       alignItems: 'center',
       justifyContent: 'center',
       display: 'none'
    },
}

let ReportData = {}
let startDate = ''
let endDate = ''
let hasDate = false
let complogo = ''
let groupByCustomer = false
let groupByJob = false
let comp_id = ''



const createData = ( company_id,fullReportData, has_date, start_date, end_date,compLogo,usergroupByCustomer,usergroupByJob) => {
    hasDate = has_date
    startDate = start_date
    endDate = end_date
    console.log('fullReportData: ',fullReportData)
    ReportData = fullReportData
    complogo = compLogo
    groupByCustomer = usergroupByCustomer
    groupByJob = usergroupByJob
    comp_id = company_id
}

const InvoiceTable= ({}) => {//data holds the sub tables data.details.og_job_no and data.details.og_comp
    return (
        <div className='flex flex-col' style={{width:'684px'}}>
            {/**company logo */}
            <div className='flex flex-row items-end align-end justify-end'>
                {
                    complogo ?
                    <img width='200px' src={complogo}/>
                    :
                    null
                }
            </div>
            {/**Report totals */}
            {/* {
                hasPermission('invoice.read') && Object.keys(ReportData).filter((agent) => !agent.includes('total')).length > 1  ?
                <>
                <div className='flex pt-2'style={{breakBefore:'avoid'}} >&nbsp;</div>
                <div className='flex flex-row w-full border-2 grid grid-cols-2 gap-y-4' style={{breakBefore:'avoid',borderColor:'black'}}>
                    <div className='flex flex-row items-center align-center justify-start pl-4'>
                        <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Total Quantity: {(ReportData.total_quantity).toFixed(2)}</Typography>
                    </div>
                    <div className='flex flex-row items-center align-center justify-end pr-4'>
                        <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Total Tickets: {ReportData.total_tickets}</Typography>
                    </div>
                    <div className='flex flex-col items-start align-center justify-start pl-4'>
                        {hasPermission('invoice.read') ? <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Total Sales Revenue: {formatter.format(ReportData.total_credit)}</Typography> : null}
                    </div>
                    <div className='flex flex-col items-end align-center justify-end pr-4'>
                        <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Total Commissions: {formatter.format(ReportData.total_commission)}</Typography>
                    </div>
                </div>
                <div className='flex pb-16'style={{breakBefore:'avoid'}} >&nbsp;</div>
                </>
                :null
            } */}

            {
                (groupByCustomer == true || groupByJob == true) && Object.keys(ReportData.customers).map((entry) => {
                    const customer = ReportData.customers[entry]
                   return(
                    <div className='flex flex-col w-full'>
                        {/**customer name */}
                        {groupByCustomer && <div className='flex flex-row justify-start w-full' style={{breakBefore:'avoid'}}>
                            <Typography fontSize={20} fontWeight={'bold'} color={'black'}>{customer?.customer_info?.user_fname}</Typography>
                        </div>}
                        {/**date if there is any and totals for agent */}
                        {
                            hasDate ?
                                <div className='flex flex-row items-center align-center justify-start' style={{breakBefore:'avoid'}}>
                                    <Typography fontSize={18} fontWeight={'bold'} color={'black'}>{moment(startDate).format("MM/DD/YY")}-{moment(endDate).format("MM/DD/YY")}</Typography>
                                </div>
                            :
                                null
                        }
                        {/**Agent Totals */}
                        {groupByCustomer && <div className='flex flex-row justify-between w-full' style={{breakBefore:'avoid'}}>
                            <div className='flex flex-col items-start align-center justify-start'>
                                <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Hauling: {formatter.format(customer.total_hauling)}</Typography>

                                <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Material: {formatter.format(customer.total_material)}</Typography>
                            </div>
                            <div className='flex flex-row items-end align-center justify-end'>
                                <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Material Tax: {formatter.format(customer.total_material_tax)}</Typography>
                            </div>
                            
                            <div className='flex flex-col items-start align-center justify-start'>
                                {hasPermission('invoice.read') ? <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Agent Commission: {formatter.format(customer.total_agent_commission)}</Typography> : null}

                                <Typography fontSize={18} fontWeight={'bold'} color={'black'}>Wait Time: {formatter.format(customer.total_waittime)}</Typography>
                            </div>
                        </div>}

                        {/**table */}
                        <div className='flex flex-col justify-center'  style={{breakBefore:'avoid'}}>
                            <Box style={{breakBefore:'avoid',width:'100%'}} sx={{marginTop: 1}}>
                                {
                                    groupByJob ? Object.keys(customer.jobs).map((elem ,i) => {
                                            const currentJob = customer.jobs[elem]
                                            return(
                                                <Box style={{pageBreakInside:'avoid'}} border={0} borderBottom={0} sx={{marginBottom: 1}} key={i}>
                                                    <AppBar className="flex flex-col items-center justify-between" position="static" elevation={0} layout="horizontal" sx={{padding:'none',margin:'none',border:2,height : "100%",width : "100%", backgroundColor: "white"}}>
                                                            <div className='flex flex-row' >
                                                                <Typography fontWeight={'bold'} fontSize={15}>
                                                                        {comp_id}-{currentJob.job_info.job_no} &nbsp; {currentJob.job_info.l_name}&nbsp;-&nbsp;{currentJob.job_info.name}&nbsp;-&nbsp;{currentJob.job_info.unit_name}
                                                                </Typography>
                                                            </div>

                                                            <div className='flex flex-row w-full items-center align-center justify-evenly'>
                                                                <Typography fontWeight={'bold'} fontSize={15}>
                                                                    Hauling: {formatter.format(currentJob.total_hauling)}
                                                                </Typography>
                                                                &nbsp;
                                                                <Typography fontWeight={'bold'} fontSize={15}>
                                                                    Material: {formatter.format(currentJob.total_material)}
                                                                </Typography>
                                                                &nbsp;
                                                                <Typography fontWeight={'bold'} fontSize={15}>
                                                                    Material Tax: {formatter.format(currentJob.total_material_tax)}
                                                                </Typography>
                                                                &nbsp;
                                                                <Typography fontWeight={'bold'} fontSize={15}>
                                                                    Wait Time: {formatter.format(currentJob.total_waittime)}
                                                                </Typography>
                                                                &nbsp;
                                                                {
                                                                    hasPermission('invoice.read') && 
                                                                    <Typography fontWeight={'bold'} fontSize={15}>
                                                                        Agent Commission: {formatter.format(currentJob.total_agent_commission)}
                                                                    </Typography>
                                                                }
                                                            </div>
                                                    </AppBar>

                                                    <Table className sx={{[`& .${tableCellClasses.root}`]: {fontSize: 14,borderBottom: 2,paddingTop: 0, paddingBottom: 0, paddingRight: 0}}}>

                                                    <TableHead sx={{ border: 2.5, padding: 'none', margin: 'none' }}>
                                                            <TableRow style={{ backgroundColor: "#2c2e35" }}>
                                                                <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Invoice No</TableCell>
                                                                <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Hauling</TableCell>
                                                                <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Wait Time</TableCell>
                                                                <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Material w/tax</TableCell>
                                                                <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Status</TableCell>
                                                                {hasPermission('invoice.read') && <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Agent Commission</TableCell>}
                                                                <TableCell sx={{ color: 'white', padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Grand Total</TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody sx={{ border: 2.5, padding: 'none', margin: 'none' }}>
                                                            {
                                                                currentJob.invoices.map((instance, i) => {
                                                                    return (
                                                                        <TableRow style={{ pageBreakInside: 'avoid' }} key={i}>
                                                                            <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{instance.invoice.invoice_no}</TableCell>
                                                                            <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{instance.invoice.unit_package_value}</TableCell>
                                                                            <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{instance.invoice.waittime_package_value}</TableCell>
                                                                            <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{formatter.format((Number(instance.invoice.material_package_vale.replace(/[$,]/g, ''))) + Number(instance.invoice.material_tax.replace(/[$,]/g, '')))}</TableCell>
                                                                            <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{instance.invoice.status}</TableCell>
                                                                            {hasPermission('invoice.read') && <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{formatter.format(instance.agent_comm)}</TableCell>}
                                                                            <TableCell align='left' sx={{ padding: '8px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{instance.invoice.invoice_total_amount}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>

                                                        <TableFooter sx={{border:0}}>
                                                            <TableRow sx={{border:0}}>
                                                                <TableCell sx={{border:0,borderColor:'white',color:'white',padding:'none'}}  id={'footer_spacer'} colSpan={6}>
    h
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableFooter>

                                                    </Table>
                                                </Box>
                                            )
                                    })
                                :
                                    <Box style={{pageBreakInside:'avoid'}} border={0} borderBottom={0} sx={{marginBottom: 1}}>
                                        <Table className sx={{[`& .${tableCellClasses.root}`]: {fontSize: 14,borderBottom: 2,paddingTop: 0, paddingBottom: 0, paddingRight: 0}}}>

                                            <TableHead sx={{border:2.5,padding:'none',margin:'none'}}>
                                                <TableRow style={{backgroundColor: "#2c2e35"}}>
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: '8px'}}>Job No</TableCell>
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: '8px'}}>Invoice No</TableCell>
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Hauling</TableCell>
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Wait Time</TableCell>
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Material w/tax</TableCell>
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Status</TableCell>
                                                    {hasPermission('invoice.read') && <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Agent Commission</TableCell>}
                                                    <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0,maxWidth:'90px'}}>Grand Total</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody sx={{border:2.5,padding:'none',margin:'none'}} >
                                                {
                                                    customer?.invoices?.map((instance, i) => {
                                                        return(
                                                            <TableRow style={{pageBreakInside:'avoid'}} key = {i} >
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: '8px'}}>{comp_id+"-"+instance.invoice.job_no}</TableCell>
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: '8px'}}>{instance.invoice.invoice_no}</TableCell>
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.unit_package_value}</TableCell>
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.waittime_package_value}</TableCell>
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{formatter.format((Number(instance.invoice.material_package_vale.replace(/[$,]/g, ''))) + Number(instance.invoice.material_tax.replace(/[$,]/g, '')))}</TableCell>
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.status}</TableCell>
                                                                {hasPermission('invoice.read') && <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{formatter.format(instance.agent_comm)}</TableCell>}
                                                                <TableCell align='left' width={'90px'} sx={{paddingLeft: 0,maxWidth:'90px'}}>{instance.invoice.invoice_total_amount}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>

                                            <TableFooter sx={{border:0}}>
                                                <TableRow sx={{border:0}}>
                                                    <TableCell sx={{border:0,borderColor:'white',color:'white',padding:'none'}}  id={'footer_spacer'} colSpan={6}>
h
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>

                                        </Table>
                                    </Box>
                                }
                            </Box>
                        </div>
                        

                    </div>
                   )
                })
            }

            {
                groupByCustomer == false && groupByJob == false && 
                    <div className='flex flex-col w-full'>
                       
                        {/**date if there is any*/}
                        {
                            hasDate ?
                                <div className='flex flex-row items-center align-center justify-start' style={{breakBefore:'avoid'}}>
                                    <Typography fontSize={18} fontWeight={'bold'} color={'black'}>{moment(startDate).format("MM/DD/YY")}-{moment(endDate).format("MM/DD/YY")}</Typography>
                                </div>
                            :
                                null
                        }
                       

                        {/**table */}
                        <div className='flex flex-col justify-center'  style={{breakBefore:'avoid'}}>
                            <Box style={{breakBefore:'avoid',width:'100%'}} sx={{marginTop: 1}}>
                                <Box style={{pageBreakInside:'avoid'}} border={0} borderBottom={0} sx={{marginBottom: 1}}>
                                    <Table className sx={{[`& .${tableCellClasses.root}`]: {fontSize: 14,borderBottom: 2,paddingTop: 0, paddingBottom: 0, paddingRight: 0}}}>

                                        <TableHead sx={{border:2.5,padding:'none',margin:'none'}}>
                                            <TableRow style={{backgroundColor: "#2c2e35"}}>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: '8px'}}>Customer</TableCell>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: '8px'}}>Job No</TableCell>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: '8px'}}>Invoice No</TableCell>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Hauling</TableCell>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Wait Time</TableCell>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Material w/tax</TableCell>
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Status</TableCell>
                                                {hasPermission('invoice.read') && <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Agent Commission</TableCell>}
                                                <TableCell width={'90px'} sx={{color: 'white', paddingLeft: 0}}>Grand Total</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody sx={{border:2.5,padding:'none',margin:'none'}} >
                                            {
                                                ReportData.invoices.map((instance, i) => {
                                                    return(
                                                        <TableRow style={{pageBreakInside:'avoid'}} key = {i} >
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: '8px'}}>{instance.customer.user_fname}</TableCell>
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: '8px'}}>{comp_id+"-"+instance.invoice.job_no}</TableCell>
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: '8px'}}>{instance.invoice.invoice_no}</TableCell>
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.unit_package_value}</TableCell>
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.waittime_package_value}</TableCell>
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{formatter.format((Number(instance.invoice.material_package_vale.replace(/[$,]/g, ''))) + Number(instance.invoice.material_tax.replace(/[$,]/g, '')))}</TableCell>
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.status}</TableCell>
                                                            {hasPermission('invoice.read') && <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{formatter.format(instance.agent_comm)}</TableCell>}
                                                            <TableCell align='left' width={'90px'} sx={{paddingLeft: 0}}>{instance.invoice.invoice_total_amount}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>

                                        <TableFooter sx={{border:0}}>
                                            <TableRow sx={{border:0}}>
                                                <TableCell sx={{border:0,borderColor:'white',color:'white',padding:'none'}}  id={'footer_spacer'} colSpan={6}>
h
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>

                                    </Table>
                                </Box>
                            </Box>
                        </div>
                        

                    </div>
                
            }
            
                    

            
        </div>
    )
}

const CombineTables = ({}) => {
    return(
        <InvoiceTable/>
    )
}


const UserTable = () => {
        return (
            <div>
                <div>
                    <ErrorBoundary>
                        <CombineTables/>
                    </ErrorBoundary>
                </div>
                <div className='footer align-center items-center justify-center'><Typography fontSize={15} sx={{marginTop: 2, marginRight: 1}}>Powered by</Typography><img style={{width: 80, height: 20, marginTop: 7}} src='assets/images/logos/loadzlogo.png'/></div>
            </div>
        )
}

export default class InvoiceReport extends React.Component {
	constructor(props) {
			super(props);
	}

	render() {
		ReportData = {}
		if(this.props) {
			
            createData(this.props.company_id,this.props.fullReportData,this.props.has_date,this.props.start_date,this.props.end_date,this.props.compLogo,this.props.usergroupByCustomer,this.props.usergroupByJob)

			return (
				<UserTable />
			)
		}
	}
}


