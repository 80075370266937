import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
  } from '@mui/material';
  import { ErrorMessage, useFormik } from 'formik';
  import { Fragment, useEffect, useState } from 'react';
  import * as Yup from 'yup';
  import CloseIcon from '@mui/icons-material/Close';
  import { FileUploader } from 'react-drag-drop-files';
  import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
  import new_store from 'app/store';
  import { postImage } from 'app/main/Register_v2/CompanyRegisterForms/CompanyRegisterFunctions';
  import { getDataFromS3 } from 'app/main/CompanyList/companyFunctions';
  import DownloadIcon from '@mui/icons-material/Download';
  import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
  import client from 'client';
  import { useSelector } from 'react-redux';
  import CircularLoader from 'app/main/loadz/components/CircularLoader';
  import Input from '@mui/material/Input';
  import FuseLoading from "@fuse/core/FuseLoading";
  import InvoicePrintReport from 'app/main/PrintReport/InvoicePrintReport'


  export const InvoiceReportModal = ({
    open, onClose, monthSelector,handleMonthChange,
    reportDateStart,reportDateEnd,setReportDateStart,
    setReportDateEnd,reportFullLoading,generateInvoiceReport,
    selectedCustomers,setSelectedCustomers,user,
    setSelectedJobs,selectedJobs,selectedStatus,setSelectedStatus,
    fullReportData,compLogo,setGroupByCustomer,setGroupByJob,groupByJob,groupByCustomer}) => {
    
    const [customersList, setCustomersList] = useState([]);
    const [jobsList, setJobsList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(async() => {
        if(open == true)
        {
            setLoading(true);

            const result = await client.post('/invoices/customers-all',{compacctnum: user?.company?.compacctnum})
            console.log('customers: ',result.data);
            setCustomersList([{name: 'All Customers',customer_id:'all'},...result.data]);

            const job_result = await client.post('/invoices/jobs-all',{compacctnum: user?.company?.compacctnum})
            console.log('jobs: ',job_result.data);
            //setJobsList([{job_no:'All Jobs',job_id:'all'},...result.data]);
            setJobsList([{job_no:'All Jobs',job_id:'all'},...job_result.data]);

            setLoading(false);
        }
    }, [open]);

    return(
        <>
            <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
        
                <DialogTitle className='flex flex-row items-center justify-center align-center p-8 ' style={{background:'#edca33'}}>
                    Invoice Report
                </DialogTitle>
                <DialogContent className='flex flex-col items-center justify-center align-center p-32 overflow-y-auto' style={{height:fullReportData && fullReportData.invoices.length  > 0 ? '850px' : '550px'}}>

                    {
                        loading ? 
                        <FuseLoading/>
                        :
                        <>
                            {/**month selector */}
                            <div className='flex flex-col w-full pt-16 justify-center items-center align-center'>
                                <Typography  variant='body1'>Select Month</Typography>
                                <Input type='month' value={monthSelector} onChange={(e) => {handleMonthChange(e.target.value)}}/>
                            </div> 

                            {/**date start & end */}
                            <div className='flex flex-row items-center justify-center align-center p-32 w-full'>
                                <div className='flex flex-col w-full items-center justify-center align-center '>
                                <Typography variant='body1'>Date Start</Typography>
                                <Input 
                                type='date'
                                value={reportDateStart}
                                style={{ height: '100%', color: 'black' }}
                                className="flex ml-8 rounded-4 shadow px-8 py-4 w-3/5 "
                                disableUnderline
                                fullWidth
                                placeholder="Date Range Start"
                                onChange={(e) => {
                                    setReportDateStart(e.target.value);
                                }}
                                />
                                </div>
                                

                                <div className='flex flex-col w-full items-center justify-center align-center '>
                                <Typography variant='body1'>Date End</Typography>
                                <Input 
                                type='date'
                                value={reportDateEnd}
                                style={{ height: '100%', color: 'black' }}
                                className="flex ml-8 rounded-4 shadow px-8 py-4 w-3/5 "
                                disableUnderline
                                fullWidth
                                placeholder="Date Range End"
                                onChange={(e) => {
                                    setReportDateEnd(e.target.value);
                                }}
                                />
                                </div>
                                
                            </div>

                            {/**customer list */}
                            <div className='flex flex-row items-center justify-center align-center pb-16 w-full'>
                                <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={customersList}
                                getOptionDisabled={(option) => {
                                    if(selectedCustomers.find((customer) => customer.customer_id == 'all'))
                                    {return true}
                                    else if(!selectedCustomers.find((customer) => customer.customer_id == 'all') && selectedCustomers.length > 0)
                                    {
                                        if(option.customer_id == 'all')
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {false}

                                }}
                                getOptionLabel={(option) => option.name}
                                filterSelectedOptions
                                value={selectedCustomers}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setSelectedCustomers(newValue);
                                }}
                                style={{ width: '35%' }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Customers"
                                    placeholder="Select Customers"
                                    />
                                )}
                                />
                            </div>

                            {/**job list */}
                            <div className='flex flex-row items-center justify-center align-center pb-16 w-full'>
                                <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={jobsList}
                                getOptionDisabled={(option) => {
                                    if(selectedJobs.find((job) => job.job_id == 'all'))
                                    {return true}
                                    else if(!selectedJobs.find((job) => job.job_id == 'all') && selectedJobs.length > 0)
                                    {
                                        if(option.job_id == 'all')
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {false}

                                }}
                                getOptionLabel={(option) => option.job_no}
                                filterSelectedOptions
                                value={selectedJobs}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setSelectedJobs(newValue);
                                }}
                                style={{ width: '35%' }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Jobs"
                                    placeholder="Select Jobs"
                                    />
                                )}
                                />
                            </div>

                            {/**status list */}
                            <div className='flex flex-row items-center justify-center align-center pb-16 w-full'>
                                <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={[{status:'All',id:'all'},{status:'Open Invoice',id:'pending'},{status:'Paid Invoiced',id:'paid'}]}
                                getOptionDisabled={(option) => {
                                    if(selectedStatus.find((status) => status.id == 'all'))
                                    {return true}
                                    else if(!selectedStatus.find((status) => status.id == 'all') && selectedStatus.length > 0)
                                    {
                                        if(option.id == 'all')
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {false}

                                }}
                                getOptionLabel={(option) => option.status}
                                filterSelectedOptions
                                value={selectedStatus}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setSelectedStatus(newValue);
                                }}
                                style={{ width: '35%' }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Status"
                                    placeholder="Select Status"
                                    />
                                )}
                                />
                            </div>

                            <div className='flex flex-row items-center justify-evenly align-center pt-8 pb-16 w-full'>
                               <div className='flex flex-col justify-center align-center'>
                                <Typography fontWeight={'bold'}>Group by customer</Typography>
                                <Checkbox checked={groupByCustomer} onChange={(e) => {e.stopPropagation();setGroupByCustomer(e.target.checked)}}/>
                               </div>
                               <div className='flex flex-col justify-center align-center'>
                                <Typography fontWeight={'bold'}>Group by job</Typography>
                                <Checkbox checked={groupByJob} onChange={(e) => {e.stopPropagation();setGroupByJob(e.target.checked)}}/>
                               </div>
                            </div>

                            {/**generate report button */}
                            <div className='flex flex-row items-center justify-center align-center p-12 w-full'>
                                {
                                reportFullLoading ? 
                                <CircularLoader/>
                                :
                                <Button variant='contained' onClick={(e) => {e.stopPropagation();generateInvoiceReport()}}>
                                Generate Report
                                </Button>
                                }
                                
                            </div> 
                        </>

                    }

                    {/**report UI */}
                    {
                        fullReportData && fullReportData.invoices.length > 0 ?
                        <InvoicePrintReport 
                            display={true} 
                            fullReportData={fullReportData} 
                            date_start={reportDateStart}  
                            date_end={reportDateEnd}
                            selectedCustomers={selectedCustomers}
                            selectedJobs={selectedJobs}
                            selectedStatus={selectedStatus}
                            compLogo={compLogo}
                            groupByJob={groupByJob}
                            groupByCustomer={groupByCustomer}
                            company_id={user?.company?.company_id}
                        />
                        :
                        fullReportData && fullReportData.invoices.length == 0 ?
                        <Typography variant='h4'>No Data Found</Typography>
                        :
                        null

                        
                    } 
                </DialogContent>

            </Dialog>
        </>
        
    )

  }