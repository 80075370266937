import {
    Box,
    Button,
    Icon,
    LinearProgress,
    List,
    ListItem,
    Typography,
  } from '@mui/material'
  import { useEffect, useRef, useState } from 'react'
  import { motion } from 'framer-motion'
  
  import client from 'client'
  import { showMessage } from 'app/store/fuse/messageSlice'
  import { useDispatch } from 'react-redux'
  import moment from 'moment'
  import FuseLoading from '@fuse/core/FuseLoading'
  import store from 'app/store'
  import EmailConfigEntry from './EmailConfigEntry'
  
  const EmailConfiguration = () => {
    //console.log('ADMIN STORE: ',store.getState().loadz_auth.AuthReducer.user.user_id)
    const [loading,setLoading] = useState(false)
    const [emails,setEmails] = useState({})//emails is an array of objects, each object contains the variable enabled and another variable,with a unique name, which is an array of emails
    const [currentLevel,setCurrentLevel] = useState('company_level') //this is to choose which ones to see if not seeing all levels at once
    const dispatch = useDispatch()
  
    useEffect(async () => {
      setEmails({})
      fetchEmails()
    }, [])
  
 
  
    const fetchEmails = async () => {
     try {
        setLoading(true)
        const result = await client.post('/props/get-admin-comms',{type:'emails',id:store.getState().loadz_auth.AuthReducer.user.user_id})
        console.log('Emails',result.data)
        if(result.data.status == false)
        {
          showError('error','Error Admin Missing')
          setEmails({})
          setLoading(false)
        }
        else
        {
          //result.data should be an object of arrays{[],[]}
          //company_emails is an array of objects
          //setEmails(result.data.company_emails)//company_level is the only level for now
          setEmails(result.data)//get all email levels for now 
          //on update only update company_emails part of the object
          setLoading(false)
        }
      
        
      } catch (e) {
        setEmails({})
        setLoading(false)
        console.log(e)
      } 

        /* const data = {company_level:[
            {type:'inspection_emails',data:['test@email.com'],enabled:true},
            {type:'invoice_emails',data:['test@email.com'],enabled:true},
            {type:'ticket_emails',data:['test@email.com'],enabled:true},
            {type:'job_emails',data:['test@email.com'],enabled:true},
            {type:'inspection_emails',data:['test@email.com'],enabled:true},
            {type:'invoice_emails',data:['test@email.com'],enabled:true},
            {type:'ticket_emails',data:['test@email.com'],enabled:true},
            {type:'job_emails',data:['test@email.com'],enabled:true}
        ]}
        console.log('Emails',data)
        console.log('Emails level',data.company_level) 
        setEmails(data)*/
      
    }

    const onChange = (idx,new_emails) => {
        //add new email
        let newEmails = JSON.parse(JSON.stringify(emails))
        //make sure to not get the enabled key
        newEmails[currentLevel][idx].data = [...new_emails]

        setEmails(newEmails)

        
    }

    const onRemove = (idx, email) => {
        // Remove email
        let newEmails = JSON.parse(JSON.stringify(emails))
        const keys = Object.keys(newEmails[idx]);
        // Make sure to not get the 'enabled' key
        const email_key = keys.filter(key => key !== 'enabled');
        newEmails[currentLevel][idx].data = newEmails[currentLevel][idx].data.filter(e => e !== email);
      
        setEmails(newEmails);
    };

    const changeEnabled = (idx,enabled) => {
        let newEmails = JSON.parse(JSON.stringify(emails))
        newEmails[currentLevel][idx].enabled = enabled
        setEmails(newEmails)
    }

    const updateEmails = async () => {
        try {
            setLoading(true)
            const result = await client.post('/props/update-admin-comms',{type:'emails',id:store.getState().loadz_auth.AuthReducer.user.user_id,data:emails})
            if(result.data.status == true)
            {
              showError('success','Emails Updated')
            }
            else
            {
              showError('error','Error Updating Emails')
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
            showError('error','Error Updating Emails')
        } 
        //console.log('Emails Updated : ',emails)
    }
  

  
    const showError = (status, message) => {
      return dispatch(
        showMessage({
          message: message, //text or html
          autoHideDuration: 6000, //ms
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: status,
        })
      )
    }

    return (
  
        
            loading ? 
            <div className='flex flex-row justify-center align-center items-center w-full h-full'>
                <FuseLoading/> 
            </div>
            :
            emails?.[currentLevel]?.length > 0 ?
            <div className='flex flex-col w-full h-full '>
                <div className='flex flex-row w-full p-32 grid grid-cols-3 gap-y-8 gap-x-8'>
                    {/**function goes here where each object in an array gets sent to the function */}
                    {
                        emails?.[currentLevel]?.map((emailGroup,idx)=>{
                            return (
                                <EmailConfigEntry emailGroup={emailGroup} email_name={emailGroup.type} index={idx} onChange={onChange} onRemove={onRemove} changeEnabled={changeEnabled}/>
                            )
                        })
                    }
                </div>
                <div className='flex w-full justify-center'>
                    <Button 
                    variant='contained'
                    onClick={(e) =>{
                        e.stopPropagation()
                        updateEmails()
                    }}>
                        Update
                    </Button>

                </div>
                
            </div>
            :
            <div className='flex flex-row justify-center align-center items-center w-full h-full'>
                <Typography variant='h6'>No Configuration Found</Typography>
            </div>
        
    )
  }
  
  
  export default EmailConfiguration
  