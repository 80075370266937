
import AuthGuard from 'app/main/AuthGuard'
import EmailConfiguration from './EmailConfiguration'

const EmailConfigurationConfig = {
  settings: {},
  routes: [
    {
      path: '/email-config',
      component: () => AuthGuard(<EmailConfiguration />),
    }
  ],
}

export default EmailConfigurationConfig
