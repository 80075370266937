export const constants = {
  URLLOCAL: process.env.BASE_URL,
  URLSOCKET: process.env.SOCKET_ENDPOINT,
  URLSTORAGE: process.env.URL_STORAGE,
  URLSECURE: process.env.URL_SECURE,
};

export const web_url = process.env.BASE_URL.replace('/api', '');

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const statesAbbreviations = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const types_checkouts = [1, 2, 3, 4, 5, 6, 7];

export const types_locations = ['Origin / Quarry', 'Destination', 'Customer'];

export const types_locations_jobs_modal = ['Origin', 'Quarry Pit'];

export const category_states = ['Active', 'Inactive'];

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const changeCurrency = (currency) => {
  var number = Number(currency.replace(/[^0-9.-]+/g, ''));
  return number;
};

export const formatPhoneNumber = (number) => {
  const areaCode = number.slice(0, 3);
  const firstPart = number.slice(3, 6);
  const secondPart = number.slice(6, 10);

  return `(${areaCode}) ${firstPart}-${secondPart}`;
};

export const getGuid = (guid) => {
  return process.env.REACT_APP_ENVIRONMENT == 'production'
    ? `https://enroll.usiopay.com/click_to_agree.aspx?id=${guid}&embedded=TRUE`
    : `https://devenroll.usiopay.com/click_to_agree.aspx?id=${guid}&embedded=TRUE`;
};

export const ROLES = {
  super_admin: 'super-admin',
};

export const PERMISSION_MODEL = {
  viewInvoice: 'view_invoice',
  applyPayment: 'apply_payment',
  clientPortalAll: 'client_portal.*',
  rolesPermissionManager: 'roles_permission_manager',
  readFinancialValue: 'read_financial_value',
  myMrofile: 'my_profile',
  downloadInvoice: 'download_invoice',
  payInvoice: 'pay_invoice',
  ownerOperatorViewInvoice: 'owner_operator_view_invoice',
  ownerOperatorApplyPayment: 'owner_operator_apply_payment',
  subContractorViewInvoice: 'sub_contractor_view_invoice',
  subContractorApplyPayment: 'sub_contractor_apply_payment',
  customersViewInvoice: 'customers_view_invoice',
  customersApplyPayment: 'customers_apply_payment',
  fastPayAccess: 'fast_pay_access',
};

export const ipayNet = Object.freeze({
  X_API_KEY: '8hEziTVtuv1CUAypieOl04wIKpL1RozW4ppOQzQC',
  project: '5e0dd0b1-d01a-402f-9f46-d72ee8d29bb6',
  referenceId: 'AFD9C66D-07AD-41FD-98DB-EB32B5A6147B',
});

export const dispatchOperations = {
  addJobInAssetDispatch: 'ADD_JOB_IN_ASSET_DISPATCH',
  removeJobFromAssetDispatch: 'REMOVE_JOB_IN_ASSET_DISPATCH',
  addInJobDispatch: 'ADD_IN_JOB_DISPATCH',
  removeFromJobDispatch: 'REMOVE_FROM_JOB_DISPATCH',
};

export const URLWOCLOUDFLARE =
  process.env.REACT_APP_ENV == 'production'
    ? `https://app-api.loadz.com/api`
    : process.env.BASE_URL;

export const webhookLoadz =
  process.env.REACT_APP_ENV == 'production'
    ? `https://app.loadz.com/webhook`
    : `https://dev.loadz.co/webhook`;
