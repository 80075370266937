import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
  } from '@mui/material';
  import { ErrorMessage, useFormik } from 'formik';
  import { Fragment, useEffect, useState } from 'react';
  import * as Yup from 'yup';
  import CloseIcon from '@mui/icons-material/Close';
  import { states } from '../../../../constants';
  import { FileUploader } from 'react-drag-drop-files';
  import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
  import { postImage } from 'app/main/Register_v2/CompanyRegisterForms/CompanyRegisterFunctions';
  import { getDataFromS3 } from 'app/main/CompanyList/companyFunctions';
  import DownloadIcon from '@mui/icons-material/Download';
  import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
  import client from 'client';
  import { useSelector } from 'react-redux';
  import CircularLoader from 'app/main/loadz/components/CircularLoader';
  import Input from '@mui/material/Input';
  import moment from 'moment-timezone';
  export const RevenueReport = ({open,setOpen,date,setDate,generateReport,loading,makeCsv,setMakeCsv,setDateEnd,dateEnd}) => {


    return(
        <>
            <Dialog open={open} onClose={() => {setDate(moment().format('YYYY-MM-DD')); setOpen(false); setMakeCsv(false); setDateEnd('')}} maxWidth='lg' fullWidth>
        
                <DialogTitle className='flex flex-row items-center justify-center align-center p-8 ' style={{background:'#edca33'}}>
                    Daily Revenue Report
                </DialogTitle>
                <DialogContent className='flex flex-col items-center justify-center align-center p-32'>

                    <div className='flex flex-row align-center items-center justify-center w-full'>  
                        <div className='flex flex-col items-center justify-center align-center p-16'>
                            <Typography variant='body1'>Date Start</Typography>
                            <Input 
                            type='date'
                            value={date}
                            style={{ height: '100%', color: 'black',width:'100%' }}
                            className="flex ml-8 rounded-4 shadow px-8 py-4 w-3/5 "
                            disableUnderline
                            fullWidth
                            placeholder="Date Range Start"
                            onChange={(e) => {
                                console.log(e.target.value);
                                setDate(e.target.value);
                            }}
                            />
                        </div>

                        <div className='flex flex-col items-center justify-center align-center p-16'>
                            <Typography variant='body1'>Date End</Typography>
                            <Input 
                            type='date'
                            value={dateEnd}
                            style={{ height: '100%', color: 'black',width:'100%' }}
                            className="flex ml-8 rounded-4 shadow px-8 py-4 w-3/5 "
                            disableUnderline
                            fullWidth
                            placeholder="Date Range End"
                            onChange={(e) => {
                                setDateEnd(e.target.value);
                            }}
                            />
                        </div>
                    </div> 

                    

                    <div className='flex flex-row items-center justify-center align-center p-12 w-full'>
                        {
                        loading ? 
                        <CircularLoader/>
                        :
                        <div className='flex flex-col justify-center align-center items-center'>
                            <div className='flex items-center align-center justify-center pb-8'>
                                <Typography variant='body1'>Generate CSV</Typography>
                               <Checkbox
                                    value={makeCsv}
                                    onChange={(e) => {setMakeCsv(!makeCsv)}}
                                /> 
                            </div>
                            
                            <Button variant='contained' onClick={() => {generateReport()}}>
                                Generate Report
                            </Button>
                        </div>
                        }
                    </div>
                    <div>
                        <Typography variant='body1' fontStyle={'italic'}>
                        *Ticket values are based on Hauling Unit Invoice Rate
                        </Typography>
                    </div>
                </DialogContent>

            </Dialog>
        </>
        
    )

  }