import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { constants, changeCurrency } from '../../../../constants';
import { Payments } from 'checkout-loadz';
import moment from 'moment';
import ModalPreview from './ModalPreview';
import store from 'app/store';
import { v4 as uuidv4 } from 'uuid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const token = localStorage.token;

export default function ModalToApplyPayments({
  open,
  setOpen,
  dataToPayments, //it is a all clients with their invoices
  setDataToPayments,
  customerId,
  rechargedAfterChanges,
  setMessage,
  showInvoiceInApply,
  setShowInvoiceInApply,
  openModalViewInvoice,
  tickets,
  setTickets,
  setInfo,
  info,
  compacctNumSub,
  isSubContractor,
  labelToSelect,
  prop_data,
}) {
  const [dataToApplyPay, setDataToApplyPay] = useState(new Map());
  const [paymentMethod, setPaymentMethod] = useState(4);
  const [datePayment, setDatePayment] = useState(moment().format('MM/DD/YYYY HH:mm:ss'));
  const [referencePayment, setReferencePayment] = useState('');
  const [amountReceivedToApply, setAmountReceivedToApply] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [textButton, setTextButton] = useState('Apply Payment');
  const [toApplyOrReceipt, setToApplyOrReceipt] = useState(true);
  const [dataClientReceipt, setDataClientReceipt] = useState({});
  const [invoicesChecked, setInvoicesChecked] = useState(new Map());
  const [isClientSelected, setIsClientSelected] = useState(false);
  const [idClientFromPayment, setIdClientFromPayment] = useState(0);
  const [fees, setFeesPay] = useState(null);
  const openPreview = useRef(null);
  // const customerMail =dataClientReceipt && dataClientReceipt.email.length > 0 && dataClientReceipt.email[0]
  // const companyMail =dataClientReceipt && dataClientReceipt.email.length > 0 && dataClientReceipt.email[0]

  useEffect(() => {
    if (open === true) {
      for (let client of dataToPayments) {
        if (client.clientSelected) {
          setIsClientSelected(true);
          break;
        }
      }
    } else {
      setIdClientFromPayment(0);
      setIsClientSelected(false);
      setDataClientReceipt({});
      setInvoicesChecked(new Map());
      setDataToApplyPay(new Map());
      setPaymentMethod(4);
      setDatePayment(moment().format());
      setReferencePayment('');
      setAmountReceivedToApply(0);
      setDisabled(true);
      setTextButton('Apply Payment');
      setToApplyOrReceipt(true);
      const newValues = [...dataToPayments];
      for (let client of newValues) {
        client.clientSelected = false;
        client.selectAll = false;
        for (let invoice of client.payments) {
          invoice.selected = false;
        }
      }
      setDataToPayments(newValues);
    }
  }, [open]);

  const handleModal = () => {
    setOpen(!open);
  };

  const setDataToPreview = () => {
    for (let client of dataToPayments) {
      if (client.clientSelected) {
        setDataClientReceipt(client);
        getInvoicesInfoToShow(client.payments);
        break;
      }
    }
  };

  const handleToReceipt = () => {
    // setDataToPreview()
    openPreview.current();
  };

  useEffect(() => {
    if (dataToPayments.length > 0) {
      // console.log("🚀 ~ file: ModalToApplyPayments.js:106 ~ useEffect ~ dataToPayments", dataToPayments)
      setDataToPreview();
    }
  }, [dataToPayments]);

  useEffect(() => {
    if (idClientFromPayment != 0) {
      setDataClientReceipt({});
      setInvoicesChecked(new Map());
      setDataToApplyPay(new Map());
      setReferencePayment('');
      setAmountReceivedToApply(0);
      setDisabled(true);
      setTextButton('Apply Payment');
      setToApplyOrReceipt(true);
      const newValues = [...dataToPayments];
      for (let client of newValues) {
        client.clientSelected = client.clientId === idClientFromPayment ? true : false;
        client.selectAll = false;
        for (let invoice of client.payments) {
          invoice.selected = false;
        }
      }
      setDataToPayments(newValues);
    }
  }, [idClientFromPayment]);

  const toPayments = (newValues, counter) => {
    if (dataToApplyPay.size > 0 && isClientSelected && invoicesChecked.size > 0) {
      try {
        dataToApplyPay.forEach((value, key) => {
          if (value) {
            let payment = newValues.payments?.find((payment, i) => payment.invoiceId === key);
            payment.select = invoicesChecked.get(payment.invoiceId);
            const valueWOCurrency =
              typeof value === 'string' ? changeCurrency(value) : value ? value : 0;
            counter += invoicesChecked.get(key) ? valueWOCurrency : 0;
            payment['payment'] = invoicesChecked.get(payment.invoiceId)
              ? dataToApplyPay.get(payment.invoiceId)
              : 0;
          }
        });
      } catch (e) {
        console.log('🚀 ~ file: ModalToApplyPayments.js:121 ~ useEffect ~ e', e);
      }

      if (
        amountReceivedToApply > 0 &&
        counter > 0 &&
        Number(amountReceivedToApply.toFixed(2)) == Number(counter.toFixed(2))
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setDataClientReceipt({ ...newValues });
      //  ? setDisabled(false) : setDisabled(true)
      //
    }
  };

  useEffect(() => {
    let counter = 0;
    let newValues = { ...dataClientReceipt };

    if (Object.keys(newValues).length > 0) {
      toPayments(newValues, counter);
    } else {
      setDataToPreview();
    }
  }, [dataToApplyPay, amountReceivedToApply, invoicesChecked]);

  useEffect(() => {
    if (paymentMethod == 1 || paymentMethod == 2 || paymentMethod == 3) {
      setToApplyOrReceipt(false);
      setTextButton('Go to Preview');
    } else {
      setToApplyOrReceipt(true);
      setTextButton('Apply Payment');
    }
  }, [paymentMethod]);

  const checkdata = {
    data: Object.fromEntries(dataToApplyPay),
    invoicesChecked: Object.fromEntries(invoicesChecked),
    paymentMethod,
    datePayment,
    amount: Number(amountReceivedToApply?.toFixed(2)),
    referencePayment,
    amountReceivedToApply: Number(amountReceivedToApply?.toFixed(2)),
    dataClientReceipt,
    fees: fees,
  };

  const sendDataToApply = async (jsonPaymentCC) => {
    setDisabled(true);
    const eventID = uuidv4();
    const state = store.getState();
    const id = prop_data ? prop_data?.id : state?.loadz_auth?.AuthReducer?.user.user_id;
    const compacctnum = prop_data
      ? prop_data.compacctnum
      : state?.loadz_auth?.AuthReducer?.user.company?.compacctnum;

    try {
      const jobs = await fetch(
        `${constants.URLLOCAL}/payments/apply-payments/${compacctNumSub ? compacctNumSub : 0}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
          body: JSON.stringify({
            data: Object.fromEntries(dataToApplyPay),
            invoicesChecked: Object.fromEntries(invoicesChecked),
            paymentMethod,
            compacctnum: compacctnum,
            userId: id,
            datePayment: moment().format(),
            referencePayment,
            amountReceivedToApply: Number(amountReceivedToApply.toFixed(2)),
            dataClientReceipt,
            eventID: eventID,
          }),
        }
      );
      const jsonPayments = await jobs.text();
      if (jobs.status === 200) {
        setDisabled(false);
        handleModal();
        let getPaymentData = JSON.parse(jsonPayments);
        getPaymentData.confirmation_ID = jsonPaymentCC.Confirmation;

        const id = prop_data ? prop_data?.id : state?.loadz_auth?.AuthReducer?.user.user_id;
        let dataBuilder = {
          data: checkdata,
          customerEmail: [dataClientReceipt.email[0], getPaymentData.compDetails.compEmail],
          otherDetail: getPaymentData,
          isSubContractor: isSubContractor,
          userId: id, // quien esta pagando
          referenceId: checkdata.dataClientReceipt.compacctNum, // aqui va a quien le estan pagando
        };
        sendDataForBalance(dataBuilder);
        setMessage.current('Balance updated succesfully!', 'success', dataBuilder); //TODO improved Message Consider adding the context of Customer X Balance updated successfully
        //TODO alternative Text : Balance for customer [Customer Name] updated successfully
        rechargedAfterChanges.current();
        return;
      }
      setDisabled(false);
      setMessage.current(
        'Unable to update the payment for the customer balance. Please try again later.!',
        'error',
        dataBuilder
      ); //TODO improved Message alternative Text : Unable to update the payment for [Customer Name] balance. Please try again later.
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  };

  const sendDataForBalance = (data) => {
    try {
      fetch(`${constants.URLLOCAL}/payments/send-email-payment-receipt`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          token: `${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          console.log('jsonemaildata', json);
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getInvoicesInfoToShow = async (data) => {
    try {
      let invoices = data.map((invoice) => invoice.invoiceId);

      const invoicesInfo = await fetch(`${constants.URLLOCAL}/invoices/info-to-show`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: `${token}`,
        },
        body: JSON.stringify({
          invoicesIds: invoices,
        }),
      });
      const invoicesInfoJSON = await invoicesInfo.json();

      const map = new Map();
      const values = Object.keys(invoicesInfoJSON);

      const clear = () => {
        setTickets([]);
        setInfo({});
      };

      const handleClickView = (tickets, info) => {
        // clear()
        setTickets(tickets);
        setInfo(info);
        openModalViewInvoice.current(false);
      };

      values.forEach((item) => {
        map.set(Number(item), () =>
          handleClickView(invoicesInfoJSON[item].ticketGLI, {
            ...invoicesInfoJSON[item],
          })
        );
      });
      console.log(map);
      setShowInvoiceInApply(map);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ModalPreview
        prop_data={prop_data}
        openPreview={openPreview}
        customersToPay={dataClientReceipt}
        referencePayment={referencePayment}
        paymentMethod={paymentMethod}
        checkdata={checkdata}
        setFeesPay={setFeesPay}
        sendDataToApply={sendDataToApply}
        payments={dataClientReceipt.payments}
        className='common-modal payment-modal'
      />
      <BootstrapDialog
        maxWidth={'xl'}
        // onClose={() => handleModal(open)}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth={true}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleModal}>
          Apply Payments
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className='flex flex-1 items-center justify-center h-full payment-modal'
          >
            <Payments
              prop_data={prop_data}
              test={false}
              customersToPay={dataToPayments || []}
              setToApplyPayments={setDataToApplyPay || (() => {})} //this is the problem to loop infinitly
              setPaymentMethodToApply={setPaymentMethod}
              setDatePaymentToApply={setDatePayment}
              setReferencePaymentToApply={setReferencePayment}
              showInvoiceInApply={showInvoiceInApply}
              setAmountReceivedToApply={setAmountReceivedToApply}
              setInvoicesChecked={setInvoicesChecked}
              setIsClientSelected={setIsClientSelected}
              isClientSelected={isClientSelected}
              setIdClientFromPayment={setIdClientFromPayment}
              labelToSelect={labelToSelect}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleModal}>
            Cancel
          </Button>

          <Button
            autoFocus
            onClick={toApplyOrReceipt ? sendDataToApply : handleToReceipt}
            disabled={disabled}
          >
            {textButton}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
